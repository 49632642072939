import { Button, Card } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Skeleton, Stack } from "@mui/material";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import pluralize from "pluralize";
import { type ReactNode } from "react";

import { ButtonInternalLink } from "../../components/ButtonInternalLink";
import { useIsJobsEnabled } from "../../PlacementCandidate/useIsJobsEnabled";
import { useFetchPaginatedPlacements } from "../api/useFetchPaginatedPlacements";
import { getWorkplacePlacementsPath } from "../paths";
import { buildPlacementsFilter } from "../utils";

interface WorkplacePlacementsAdCardProps {
  workplaceId: string;
}

interface CardWrapperProps {
  children: ReactNode;
}

function CardWrapper(props: CardWrapperProps) {
  const { children } = props;

  return (
    <Card
      variant="tertiary"
      sx={{
        flexGrow: 1,
        background: (theme) => theme.palette.placement?.advertisement.gradient,
      }}
    >
      <CardContent sx={{ py: 9, px: 12, "&:last-child": { pb: 9 } }}>{children}</CardContent>
    </Card>
  );
}

function WorkplacePlacementsAdCardSkeleton() {
  return (
    <CardWrapper>
      <Stack alignItems="center" spacing={4} aria-label="workplace placements ad card skeleton">
        <Stack spacing={2} width="100%" alignItems="center">
          <Skeleton variant="rounded" width="70%" height={36} />
          <Skeleton variant="rounded" width="90%" height={28} />
        </Stack>
        <Skeleton variant="rounded" width={120} height={36} />
      </Stack>
    </CardWrapper>
  );
}

export function WorkplacePlacementsAdCard(props: WorkplacePlacementsAdCardProps) {
  const { workplaceId } = props;
  const isJobsEnabled = useIsJobsEnabled();
  const worker = useDefinedWorker();

  const { data: placementsData, isLoading: isPlacementsLoading } = useFetchPaginatedPlacements(
    {
      workerId: worker.userId,
      placementCandidateId: "",
      filter: buildPlacementsFilter({
        filter: { workplaceId },
        worker,
      }),
    },
    {
      enabled: isJobsEnabled && isDefined(worker.userId),
    }
  );

  const placementsCount =
    placementsData?.pages.reduce((count, page) => count + page.data.length, 0) ?? 0;

  if (!isJobsEnabled || (!isPlacementsLoading && placementsCount === 0)) {
    return null;
  }

  const uniquePlacementWorkerTypes = [
    ...new Set(
      placementsData?.pages.flatMap((page) =>
        page.data.flatMap((placement) => placement.attributes.workerTypes)
      ) ?? []
    ),
  ];
  const activeWorkerTypes = new Set(worker.licensesData.map((license) => license.qualification));

  const matchingWorkerTypes = uniquePlacementWorkerTypes
    .filter((type): type is string => typeof type === "string")
    .filter((type) => activeWorkerTypes.has(type));

  if (isPlacementsLoading) {
    return <WorkplacePlacementsAdCardSkeleton />;
  }

  return (
    <CardWrapper>
      <Stack justifyContent="center" alignItems="center" spacing={4}>
        <Stack spacing={2}>
          <Text semibold variant="h6" textAlign="center">
            Interested in a permanent role at this facility?
          </Text>
          <Text variant="body2" textAlign="center">
            This workplace is hiring for{" "}
            {matchingWorkerTypes.length > 0
              ? matchingWorkerTypes.join(", ")
              : uniquePlacementWorkerTypes.join(", ")}
          </Text>
        </Stack>
        <Button
          variant="outlined"
          size="small"
          LinkComponent={ButtonInternalLink}
          href={getWorkplacePlacementsPath(workplaceId, "workplace-placements-ad-card")}
        >
          Explore {placementsCount} {pluralize("job", placementsCount)}
        </Button>
      </Stack>
    </CardWrapper>
  );
}
