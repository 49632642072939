// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { CounterText } from "@clipboard-health/ui-components";
import {
  InternalLink,
  LoadingButton,
  Text,
  Title,
  type UseModalState,
  useModalState,
} from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
// TODO: Use our own Button
// eslint-disable-next-line no-restricted-imports
import { Button, DialogContent, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useSearchParams } from "@src/appV2/lib/utils/useSearchParams";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import {
  isJobType,
  isShiftNameType,
  type PlacementCandidate,
} from "@src/appV2/redesign/PlacementCandidate/types";
import { useWorkplaceModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useWorkplaceModalsDataContext";
import { type WorkplaceProfile } from "@src/appV2/redesign/Workplace/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { filter } from "lodash";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  transformResponseIntoPlacement,
  useFetchPaginatedPlacements,
} from "../api/useFetchPaginatedPlacements";
import { EstimatedPayBottomSheet } from "../components/EstimatedPayBottomSheet";
import { PlacementCard } from "../components/PlacementCard";
import { PlacementsLoader } from "../components/PlacementsLoader";
import { useComputedPayData } from "../lib/useComputedPay";
import {
  PLACEMENT_PATHS,
  PLACEMENT_WORKPLACE_PROFILE_PATH,
  type WorkplacePlacementsPathSource,
} from "../paths";
import type { PlacementData } from "../types/fetchPlacements.schema";
import { usePlacementsFilter } from "../usePlacementsFilter";
import { buildPlacementsFilter } from "../utils";

interface WorkplacePlacementsProps {
  workplaceId: string;
  workplace?: WorkplaceProfile;
  modalState: UseModalState;
  placementCandidate: PlacementCandidate;
}

export function WorkplacePlacements(props: WorkplacePlacementsProps) {
  const { workplaceId, modalState, workplace, placementCandidate } = props;

  const history = useHistory();
  const { generateModalPath } = useWorkplaceModalsDataContext();
  const worker = useDefinedWorker();
  const estimatedPayBottomSheetModalState = useModalState();
  const [placement, setPlacement] = useState<PlacementData | undefined>(undefined);

  const [placementsFilter] = usePlacementsFilter();

  const {
    data: placementsData,
    isLoading: isPlacementsLoading,
    isSuccess: isPlacementsSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFetchPaginatedPlacements(
    {
      workerId: worker.userId,
      placementCandidateId: placementCandidate.id ?? "",
      filter: buildPlacementsFilter({
        filter: { workplaceId },
        worker,
      }),
    },
    {
      enabled: isDefined(worker.userId),
    }
  );

  const placementsCount = placementsData?.pages.reduce(
    (count, page) => count + page.data.length,
    0
  );

  const placements = placementsData?.pages.flatMap((page) => page.data) ?? [];
  const { payData, isLoading: isPayDataLoading } = useComputedPayData(placements);

  const scrollRef = useRef<HTMLDivElement>(null);

  const searchParams = useSearchParams();
  const source = searchParams.source as WorkplacePlacementsPathSource;
  return (
    <>
      <StickyScrollPageHeader
        title={workplace?.name ?? ""}
        isLoading={isPlacementsLoading}
        scrollContainerRef={scrollRef}
      />

      <DialogContent ref={scrollRef} sx={{ px: 7, pt: 0 }}>
        <Stack spacing={8}>
          {placementsCount ? (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <CounterText count={placementsCount}>
                <Title
                  bold
                  variant="h5"
                  component="h4"
                  sx={(theme) => ({ fontWeight: theme.typography.fontWeightMedium })}
                >
                  Jobs
                </Title>
              </CounterText>
              {source === "workplace-placements-ad-card" ? (
                <InternalLink to={PLACEMENT_PATHS.ROOT}>Explore all jobs</InternalLink>
              ) : undefined}
            </Stack>
          ) : undefined}

          <Stack spacing={6}>
            {isPlacementsLoading ? <PlacementsLoader /> : undefined}

            {isPlacementsSuccess && (placementsData.pages[0]?.data.length ?? 0) === 0 ? (
              <Stack justifyContent="center" flex={1} alignItems="center">
                <Text variant="body1">No relevant jobs listed yet!</Text>
              </Stack>
            ) : undefined}

            {isDefined(placementsData) && placementsData.pages.length > 0 ? (
              <Stack direction="column" spacing={6}>
                {placementsData?.pages.flatMap((placementPage, pageIndex) => {
                  return placementPage.data.map((placement, placementIndex) => {
                    const placementWithDetails = transformResponseIntoPlacement(
                      placement,
                      placementPage.included
                    );
                    return (
                      <PlacementCard
                        key={placementWithDetails.id}
                        placement={placementWithDetails}
                        placementCandidateId={placementCandidate.id}
                        pageIndex={pageIndex}
                        placementIndex={placementIndex}
                        payRate={payData.get(placementWithDetails.id)}
                        loadingPayData={isPayDataLoading}
                        shiftTypeFilter={placementsFilter?.shiftTypes
                          ?.split(",")
                          .filter(isShiftNameType)}
                        jobTypeFilter={placementsFilter?.jobTypes?.split(",").filter(isJobType)}
                        onPayRateClick={() => {
                          setPlacement(placement);
                          estimatedPayBottomSheetModalState.openModal();
                        }}
                      />
                    );
                  });
                })}
              </Stack>
            ) : undefined}

            {hasNextPage ? (
              <Stack direction="row" justifyContent="center">
                <LoadingButton
                  sx={{ mb: 10 }}
                  isLoading={isFetchingNextPage}
                  onClick={async () => {
                    await fetchNextPage();
                    logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
                      placementCandidateId: placementCandidate.id,
                      filters: { ...filter },
                      action: "load_more_clicked",
                    });
                  }}
                >
                  Load more
                </LoadingButton>
              </Stack>
            ) : undefined}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogFooter
        withShadow
        orientation="horizontal"
        backgroundColor={(theme) => String(theme.palette.background.tertiary)}
        onClose={() => {
          modalState.closeModal();
        }}
      >
        <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{ maxWidth: "60%" }}
          onClick={() => {
            history.push(
              generateModalPath(PLACEMENT_WORKPLACE_PROFILE_PATH, {
                workplaceId,
              })
            );
          }}
        >
          View workplace profile
        </Button>
      </DialogFooter>
      <EstimatedPayBottomSheet
        modalState={estimatedPayBottomSheetModalState}
        placement={placement}
      />
    </>
  );
}
