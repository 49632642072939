import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useCreateOrGetPlacementCandidate } from "@src/appV2/redesign/PlacementCandidate/api/useCreateOrGetPlacementCandidate";
import { PlacementCandidateOnboardingContainer } from "@src/appV2/redesign/PlacementCandidate/components/PlacementCandidateOnboardingContainer";
import { PlacementCandidateStatus } from "@src/appV2/redesign/PlacementCandidate/types";
import { type WorkplaceProfile } from "@src/appV2/redesign/Workplace/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useRef } from "react";

import { useTrackMarketingLinkClicks } from "../../PlacementCandidate/hooks/useTrackMarketingLinkClicks";
import { PlacementsLoader } from "../components/PlacementsLoader";
import { WorkplacePlacements } from "./WorkplacePlacements";

interface WorkplaceJobsProps {
  workplaceId: string;
  isLoading?: boolean;
  workplace?: WorkplaceProfile;
  modalState: UseModalState;
}

export function WorkplacePlacementsModal(props: WorkplaceJobsProps) {
  const { workplaceId, isLoading, modalState, workplace } = props;

  const worker = useDefinedWorker();
  const {
    data: placementCandidate,
    isLoading: isPlacementCandidateLoading,
    isSuccess: isPlacementCandidateSuccess,
  } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );

  useTrackMarketingLinkClicks({
    placementCandidate,
    workplaceId,
    enabled: isDefined(placementCandidate),
  });

  const scrollRef = useRef<HTMLDivElement>(null);

  const isLoadingAnything = isPlacementCandidateLoading || isLoading;
  const hasExistingPlacementCandidate =
    isPlacementCandidateSuccess &&
    (placementCandidate.status === PlacementCandidateStatus.ACTIVATED ||
      placementCandidate.status === PlacementCandidateStatus.ONBOARDING);

  if (!isLoadingAnything && isPlacementCandidateSuccess && hasExistingPlacementCandidate) {
    return (
      <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
        <WorkplacePlacements
          workplaceId={workplaceId}
          workplace={workplace}
          modalState={modalState}
          placementCandidate={placementCandidate}
        />
      </FullScreenDialog>
    );
  }

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <StickyScrollPageHeader
        title={workplace?.name ?? ""}
        isLoading={isLoading}
        scrollContainerRef={scrollRef}
      />

      <DialogContent
        ref={scrollRef}
        sx={{ px: 7, pt: 0, display: "flex", flexDirection: "column" }}
      >
        {isLoadingAnything && <PlacementsLoader />}

        {!isLoadingAnything && isPlacementCandidateSuccess && !hasExistingPlacementCandidate && (
          <PlacementCandidateOnboardingContainer
            worker={worker}
            placementCandidate={placementCandidate}
          />
        )}
      </DialogContent>
    </FullScreenDialog>
  );
}
